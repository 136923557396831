import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlTextarea from "@shoelace-style/react/dist/textarea";
import SlDivider from "@shoelace-style/react/dist/divider";

const CommunitiesCreatePage: React.FunctionComponent = () => {
  const { create } = useActions().communities;

  return (
    <>
      <SlForm
        onSlSubmit={e => {
          // @ts-ignore
          const name = e.detail.formData.get("name");
          // @ts-ignore
          const description = e.detail.formData.get("description");

          create({ name, description });
        }}
      >
        <SlInput
          name="name"
          label="Name"
          type="text"
          placeholder="Name"
          required
        />
        <SlDivider />
        <SlTextarea
          name="description"
          label="Description"
          placeholder="Description"
          required
        />
        <SlDivider />
        <SlButton submit>Submit</SlButton>
      </SlForm>
    </>
  );
};

export default CommunitiesCreatePage;
