import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlMenu from "@shoelace-style/react/dist/menu";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlDivider from "@shoelace-style/react/dist/divider";
import SlFormatDate from "@shoelace-style/react/dist/format-date";

const Message: ({ createdAt }: { createdAt: Date }) => JSX.Element = ({
  createdAt
}: {
  createdAt: Date;
}) => {
  const { login } = useActions();

  return (
    <>
      <small>
        <SlFormatDate
          date={createdAt}
          month="long"
          day="numeric"
          year="numeric"
          hour="numeric"
          minute="numeric"
        />
      </small>
    </>
  );
};

export default Message;
