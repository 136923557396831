import { Context } from "../../index";
import { SupportedMimeTypesSubTypeMap } from "./state";
import { DialogButton } from "../../state";

export const create = async (
  { state, effects, actions }: Context,
  { name, description }: { description: string; name: string }
) => {
  try {
    const { insert_communities_one } = await effects.gql.mutations.create({
      name,
      description
    });
    const { id } = insert_communities_one;

    if (id) {
      actions.toast({ message: "Create success" });
      state.redirectTo = "/communities/list";
    }
  } catch (error) {
    actions.toast({ error });
  }
};

export const getCommunities = async ({ state, effects, actions }: Context) => {
  try {
    actions.communities.resetState();
    const result = await effects.gql.queries.list({});
    state.communities.list = result.communities;
  } catch (error) {
    actions.toast({ error });
  }
};

export const manage = async (
  { state, effects, actions }: Context,
  { id }: { id: string }
) => {
  try {
    state.communities.currentCommunityId = id;
    actions.communities.getMessages({ id });
    state.redirectTo = `/communities/manage/${id}`;
    // const result = await effects.gql.queries.list({});
    // state.communities.list = result.communities;
    // console.log(result);
  } catch (error) {
    actions.toast({ error });
  }
};

export const getMessages = async (
  { state, effects, actions }: Context,
  { id }: { id: string }
) => {
  try {
    if (!state.communities.currentCommunityId) {
      state.communities.currentCommunityId = id;
    }
    const { messages } = await effects.gql.queries.getMessages({
      communityId: id
    });

    if (messages.length) {
      state.communities.messages = messages.map((m: any) => {
        let safeMessage;
        try {
          safeMessage = JSON.parse(m.message);
        } catch (e) {}
        return {
          ...m,
          ...safeMessage
        };
      });
    }
  } catch (error) {
    actions.toast({ error });
  }
};

export const submitMessage = async ({ state, effects, actions }: Context) => {
  try {
    const message = state.communities.communityManageInput;

    const result = await effects.gql.mutations.createMessage({
      communityId: state.communities.currentCommunityId,
      message
    });

    actions.communities.getMessages({
      id: state.communities.currentCommunityId
    });

    state.communities.communityManageInput = "";

    const communityId = state.communities.currentCommunityId;
    const messageId = result.insert_messages_one.id;

    effects.api.createBroadcastJob({ communityId, message, messageId });
  } catch (error) {
    actions.toast({ error });
  }
};

export const setManageInput = async (
  { state, effects, actions }: Context,
  { value }: { value: string }
) => {
  state.communities.communityManageInput = value;
};

export const resetState = async ({ state, effects, actions }: Context) => {
  state.communities.communityManageInput = "";
  state.communities.list = [];
  state.communities.messages = [];
};

function validFileToUpload({ type }: { type: string }) {
  return type in SupportedMimeTypesSubTypeMap;
}

export const uploadFile = async (
  { state, effects, actions }: Context,
  { file }: { file: File }
) => {
  try {
    const { type }: { type: string; name: string } = file;

    if (!validFileToUpload({ type })) {
      actions.toast({
        message: "File not supported"
      });
      return;
    }

    actions.toast({
      message: "Uploading file",
      closable: false,
      duration: 99999
    });
    const { url } = await effects.api.uploadFile({ file });

    if (url) {
      state.communities.communityManageInput = JSON.stringify({
        filename: file.name,
        filesize: file.size,
        subtype:
          SupportedMimeTypesSubTypeMap[
            type as keyof typeof SupportedMimeTypesSubTypeMap
          ],
        body: url
      });
      actions.communities.submitMessage();
    }
  } catch (error) {
    actions.toast({ message: error.message });
  }
  setTimeout(() => {
    actions.dismissToast();
  }, 1000);
};

export const deleteMessage = async (
  { state, effects, actions }: Context,
  { id, index }: { id: string; index: number }
) => {
  const { answer } = await actions.confirmDialog();
  if (answer === DialogButton.yes) {
    try {
      const {
        delete_messages_by_pk
      } = await effects.gql.mutations.deleteMessage({
        messageId: id
      });
      if (delete_messages_by_pk.id) {
        actions.toast({ message: "Deleted " });
        state.communities.messages.splice(index, 1);
      }
    } catch (error) {
      actions.toast({ error });
    }
  }
};

export const assignOperator = async (
  { state, effects, actions }: Context,
  { communityId, operatorId }: { communityId: string; operatorId: string }
) => {
  try {
    const {
      update_communities_by_pk
    } = await effects.gql.mutations.assignOperator({ communityId, operatorId });
    const { update_operators } = await effects.gql.mutations.assignOperatorCom({
      communityId,
      operatorId
    });
    actions.toast({ message: "Updated" });
    actions.communities.getCommunities();
  } catch (error) {
    actions.toast({ error });
  }
};
export const setCommunityIdForOperatorAssignment = async (
  { state, effects, actions }: Context,
  { communityId }: { communityId: string }
) => {
  state.communities.communityIdForOperatorAssignment = communityId;
};

export const deleteCommunity = async (
  { state, effects, actions }: Context,
  { communityId }: { communityId: string }
) => {
  const { answer } = await actions.confirmDialog();
  if (answer === DialogButton.yes) {
    try {
      const {
        delete_communities_by_pk
      } = await effects.gql.mutations.deleteCommunity({
        communityId
      });
      actions.toast({ message: "Deleted " });
      actions.communities.getCommunities();
    } catch (error) {
      actions.toast({ error });
    }
  }
};
