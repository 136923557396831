import React from "react";
import { useActions, useAppState } from "../main";
import SlButton from "@shoelace-style/react/dist/button";
import SlForm from "@shoelace-style/react/dist/form";
import SlInput from "@shoelace-style/react/dist/input";
import SlCard from "@shoelace-style/react/dist/card";
import { comlist, css, fillHeight, header, menu } from "../stitches.config";
import CreatedAt from "../components/CreatedAt";
import SlIconButton from "@shoelace-style/react/dist/icon-button";
import SlMenu from "@shoelace-style/react/dist/menu";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlDivider from "@shoelace-style/react/dist/divider";
import SlQrCode from "@shoelace-style/react/dist/qr-code";
import SlDialog from "@shoelace-style/react/dist/dialog";

const card = css({
  width: "100%",
  paddingRight: "1rem",
  paddingBottom: "1rem"
});
const rightpane = css({
  width: "20%",
  flexGrow: 2
});
const ManageOperatorsPage: React.FunctionComponent = () => {
  const {
    getOperators,
    createNewOperator,
    deleteOperator
  } = useActions().operators;
  const { list } = useAppState().operators;

  React.useEffect(() => {
    getOperators();
  }, []);
  return (
    <>
      <div className={fillHeight()}>
        <div className={comlist()}>
          {list
            .map(
              (
                { email, id, name, updated_at, created_at, otp_secret },
                index
              ) => (
                <SlCard className={card()}>
                  <span className={`${header()} ${card()}`} slot="header">
                    <strong>{name}</strong>
                    <SlIconButton
                      onClick={() => deleteOperator({ email, index })}
                      name="trash"
                      label="Delete"
                    />
                  </span>
                  <span>{email}</span>
                  <br />
                  <CreatedAt createdAt={created_at} />
                  <SlQrCode slot="footer" value={`otpauth://totp/${name}:${email}(${document.location.hostname})?secret=${otp_secret}`} />
                </SlCard>
              )
            )
            .reverse()}
        </div>
      </div>
      {/* Right Sidebar */}
      <div className={`${rightpane()} ${fillHeight()}`}>
        <SlMenu className={`${menu()}`}>
          <SlMenuItem
            onClick={() => {
              // console.log({ route: "/operators/manage" });
              const dialog: any = document.querySelector(".addDialog");
              dialog.show();
            }}
          >
            Add new
          </SlMenuItem>
          <SlDivider />
        </SlMenu>
      </div>
      {/* Hidden dialog for asking add new email */}
      <SlDialog label="Enter an email address" className="addDialog">
        <SlForm
          id="addForm"
          onSlSubmit={e => {
            // @ts-ignore
            const email = e.detail.formData.get("email");
            // @ts-ignore
            const name = e.detail.formData.get("name");

            createNewOperator({ email, name });

            const dialog: any = document.querySelector(".addDialog");
            dialog.hide();
            // @ts-ignore
            const controls = document
              .getElementById("addForm")
              // @ts-ignore
              .getFormControls();
            // @ts-ignore
            for (let control of controls) {
              switch (control.tagName.toLowerCase()) {
                case "sl-checkbox":
                case "sl-radio":
                  control.checked = false;
                  break;

                default:
                  control.value = "";
              }
            }
          }}
        >
          <SlInput name="name" type="text" placeholder="John Doe" />
          <br />
          <SlInput name="email" type="email" placeholder="example@world.com" />
        </SlForm>
      </SlDialog>
    </>
  );
};

export default ManageOperatorsPage;
