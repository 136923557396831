import CommunitiesCreatePage from "./CommunitiesCreatePage";
import CommunitiesListPage from "./CommunitiesListPage";
import LoginPage from "./LoginPage";
import RootPage from "./RootPage";
import CommunitiesManagePage from "./CommunitiesManagePage";
import ManageOperatorsPage from "./ManageOperatorsPage";

export default [
  { Page: LoginPage, route: "/login" },
  { Page: RootPage, route: "/" },
  { Page: CommunitiesCreatePage, route: "/communities/create" },
  { Page: CommunitiesListPage, route: "/communities/list" },
  { Page: CommunitiesManagePage, route: "/communities/manage/:communityId" },
  { Page: ManageOperatorsPage, route: "/operators/manage" }
];
