import { createStitches } from "@stitches/react";

export const { styled, css } = createStitches({
  media: {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)"
  }
});

export const fillHeight = css({
  height: "96vh",
  flexGrow: 1,
  overflowY: "scroll"
});

export const comlist = css({
  width: "100%",
  marginBottom: "1em"
});

export const header = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
});

export const menu = css({
  height: "96vh",
  border: "solid 1px rgb(var(--sl-panel-border-color))",
  borderRadius: "var(--sl-border-radius-medium)"
});

export const padr = css({
  paddingRight: "0.5rem"
});
