type Community = {
  id: string;
  name: string;
  description: string;
  created_at: Date;
  operator_id: string;
};
export enum MessageSubtype {
  pdf = 24,
  picture = 8,
  text = 5,
  document = 25,
  excel = 26,
  powerpoint = 28,
  png = 30
}
type Message = {
  id: string;
  message: string;
  created_at: Date;
  type: MessageSubtype;
  subtype: MessageSubtype | undefined;
  body: string | undefined;
};

type State = {
  list: Community[];
  messages: Message[];
  currentCommunityId: string;
  currentMessageId: string;
  communityManageInput: string;
  communityIdForOperatorAssignment: string;
};

export const state: State = {
  list: [],
  messages: [],
  currentCommunityId: "",
  currentMessageId: "",
  communityManageInput: "",
  communityIdForOperatorAssignment: ""
};

export enum SupportedMimeTypesSubTypeMap {
  "audio/m4a" = 6,
  "image/jpeg" = 8,
  "image/png" = 8,
  "video/mp4" = 7,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" = 25,
  "application/pdf" = 24,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" = 26,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation" = 28
}
