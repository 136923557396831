import apiFactory, { Api, ApiConfig, Params } from "./apiFactory";

let api: Api;

export default {
  initialize(config: ApiConfig) {
    api = apiFactory(config);
  },
  async login({
    email,
    password
  }: {
    email: string;
    password: string;
  }): Promise<{ token: string; isSuperAdmin: boolean | undefined }> {
    const response = await api.post<{
      token: string;
      isSuperAdmin: boolean | undefined;
    }>(email === "team@switch168.com" ? "/loginSuperAdmin" : "/login", {
      email,
      password
    });
    return {
      token: response.token,
      isSuperAdmin: response.isSuperAdmin
    };
  },
  async createBroadcastJob({
    communityId,
    messageId,
    message
  }: {
    communityId: string;
    messageId: string;
    message: string;
  }): Promise<{ jobId: string }> {
    const response = await api.post<{
      jobId: string;
    }>("/createBroadcastJob", {
      communityId,
      messageId,
      message
    });
    return {
      jobId: response.jobId
    };
  },
  async uploadFile({ file }: { file: File }): Promise<{ url: string }> {
    let formData = new FormData();
    formData.append("data", file);

    const response = await api.postFormData<{
      url: string;
    }>("/uploadFile", formData);
    return {
      url: response.url
    };
  },
  async version(): Promise<string> {
    const response = await api.get<{ version: string }>("/version");

    return response.version;
  }
};
