import React from "react";
import { useActions, useAppState } from "../main";
import SlCard from "@shoelace-style/react/dist/card";
import SlDialog from "@shoelace-style/react/dist/dialog";
import SlSelect from "@shoelace-style/react/dist/select";
import SlMenuItem from "@shoelace-style/react/dist/menu-item";
import SlButton from "@shoelace-style/react/dist/button";

import { comlist, fillHeight, header } from "../stitches.config";
import SlIconButton from "@shoelace-style/react/dist/icon-button";
import CreatedAt from "../components/CreatedAt";
import { RunMode } from "../main/state";

const CommunitiesListPage: React.FunctionComponent = () => {
  const {
    getCommunities,
    manage,
    assignOperator,
    setCommunityIdForOperatorAssignment,
    deleteCommunity
  } = useActions().communities;
  const { getOperators } = useActions().operators;
  const { list, communityIdForOperatorAssignment } = useAppState().communities;
  const operators = useAppState().operators;
  const runMode = useAppState().runMode;

  React.useEffect(() => {
    getCommunities();
    getOperators();
  }, []);
  return (
    <>
      <div className={fillHeight()}>
        <div className={comlist()}>
          {list
            .map(({ name, description, id, created_at, operator_id }) => {
              return (
                <SlCard className={comlist()}>
                  <span className={header()} slot="header">
                    <strong>{name}</strong>
                    {runMode === RunMode.superadmin && (
                      <SlIconButton
                        // @ts-ignore
                        // push to the right
                        style={{ marginLeft: "auto" }}
                        onClick={() => {
                          // console.log({ route: "/operators/manage" });
                          const dialog: any = document.querySelector(
                            ".assignOperatorDialog"
                          );
                          dialog.show({ communityId: id });
                          setCommunityIdForOperatorAssignment({
                            communityId: id
                          });
                          // assignOperator({ communityId: id })
                        }}
                        name="person-plus"
                        label="Assign Operator"
                      />
                    )}
                    <SlIconButton
                      onClick={() => manage({ id })}
                      name="list"
                      label="Manage"
                    />
                    {runMode === RunMode.superadmin && (
                      <SlIconButton
                        onClick={() => deleteCommunity({ communityId: id })}
                        name="trash"
                        label="Delete Communities"
                      />
                    )}
                  </span>
                  <span>{description}</span>
                  <br />
                  <CreatedAt createdAt={created_at} />
                  <br />

                  {operator_id && (
                    // @ts-ignore
                    <span>
                      Assigned Operator:
                      {// @ts-ignore
                      operators.map[operator_id] &&
                        // @ts-ignore
                        operators.map[operator_id].name}
                      {/*{JSON.stringify(operators.map[operator_id])}*/}
                    </span>
                  )}
                </SlCard>
              );
            })
            .reverse()}
        </div>
      </div>
      {/* Hidden dialog for asking add new email */}
      <SlDialog label="Assign an Operator" className="assignOperatorDialog">
        <SlSelect id="selectedOperator" placeholder="Select one">
          {operators.list.map(operator => {
            return <SlMenuItem value={operator.id}>{operator.name}</SlMenuItem>;
          })}
        </SlSelect>
        <SlButton
          onClick={() => {
            var e: any = document.getElementById("selectedOperator");
            let operatorId;
            if (e.value) {
              operatorId = e.value;
              assignOperator({
                operatorId,
                communityId: communityIdForOperatorAssignment
              });
              const dialog: any = document.querySelector(
                ".assignOperatorDialog"
              );
              dialog.hide();
            }
          }}
          slot="footer"
          type="primary"
        >
          Assign
        </SlButton>
      </SlDialog>
    </>
  );
};

export default CommunitiesListPage;
